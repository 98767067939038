<template>
  <div>
    <div class="fixed top">
      <div class="flex flex-center">
        <div class="material-nav flex-item_center" @click="labelClick(index)"
          :class="labelIndex == index ? 'material-select_nav' : ''" v-for="(item, index) in label" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="nav">
        <div v-for="(item, index) in nav" :key="index" v-if="item.name != '音频'" class="navItem"
          @click="navClick(item, index)">
          <div class="navItem-name">{{ item.name }}</div>
          <div class="nav-underline" v-if="index == navIndex"></div>
        </div>
      </div>
      <!-- <div class="search flex-item_center">
        <div class="searchItem flex-item_center">
          <img src="../assets/imgs/search.png" alt="" />
          <input type="text" class="" placeholder="搜索标题" v-model="title" />
        </div>
      </div> -->
    </div>
    <div class="topWhile"></div>

    <div v-for="(item, index) in items" :key="index">
      <!-- 文章 -->
      <div class="article flex flex-center rel" v-if="type == 'article'">
        <img class="article-img" src="../assets/imgs/activity.png" alt="" />
        <div>
          <div class="article-title">文章标题文章标题文qeqweqw章标题</div>
          <div class="article-text">
            简介简介简介简介简介简介简介 简介简介简介简介简介简介简介dasdasda
          </div>
          <div class="flex flex-center">
            <div class="article-label flex-item_center">健身</div>
          </div>
          <img src="../assets/imgs/share.png" class="abs article-icon" alt="" />
        </div>
      </div>

      <!-- 文字 -->
      <div class="text flex rel" v-if="type == 'text'">
        <div class="textItem flex flex-center">
          这里来写话术文案这里来写话术文案这里
          来写话术文案这里来写话术文案这里来写
          最长这么长阿达伟大阿达的群岛亲卫队我的期望的对我期望的气味的权威的亲卫队请问单位签订我的去
        </div>
        <img src="../assets/imgs/share.png" class="abs article-icon" alt="" />
      </div>

      <!-- 图片 -->
      <div v-if="type == 'image'">
        <div class="video flex flex-center" v-if="toolbar" @click="send(item, 'image')">
          <img :src="item.img_url" class="video-img" alt="" />
          <img src="../assets/imgs/share.png" class="article-icon_video" alt="" />
        </div>
        <div class="remind" v-else>
          <div class="flex flex-center">
            <img class="remind-img" :src="item.img_url" alt="" />
          </div>
          <div class="remind-bottom flex flex-center">
            <div class="flex-item_center remind-bottom_item" style="border-right:1px solid #ededf7;"
              @click="share(item, 1, 'image')">
              <img src="../assets/imgs/share.png" alt="" />
              <div>个人</div>
            </div>
            <div class="flex-item_center remind-bottom_item" @click="share(item, 2, 'image')">
              <img src="../assets/imgs/share.png" alt="" />
              <div>群聊</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 视频 -->
      <!-- <div class="video flex flex-center" v-if="type == 'video'">
        <video :src="item.img_url" class="video-img"></video>
        <img src="../assets/imgs/share.png" class="article-icon_video" alt="" />
      </div> -->
      <div v-if="type == 'video'">
        <div class="video flex flex-center" v-if="toolbar" @click="send(item, 'video')">
          <img class="remind-img" style=" width: 50%;margin: 0 auto;" src="../assets/imgs/video.jpg" v-if="item.img_url" />
          <img src="../assets/imgs/share.png" class="article-icon_video" alt="" />
        </div>
        <div class="remind" v-else>
          <div class="flex flex-center">
            <img class="remind-img" style="width: 50%;margin: 0 auto;" src="../assets/imgs/video.jpg" v-if="item.img_url" />
          </div>
          <div class="title_name">{{ item.title}}</div>
          <div class="remind-bottom flex flex-center margin-0">
            <div class="flex-item_center remind-bottom_item" style="border-right:1px solid #ededf7;"
              @click="share(item, 1, 'video')">
              <img src="../assets/imgs/share.png" alt="" />
              <div>个人</div>
            </div>
            <div class="flex-item_center remind-bottom_item" @click="share(item, 2, 'video')">
              <img src="../assets/imgs/share.png" alt="" />
              <div>群聊</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 音频 -->
      <div class="audio flex flex-center" v-if="type == 'voice'">
        <div class="audioItem flex flex-center">
          <img src="../assets/imgs/audio.png" class="audioItem-icon" alt="" />
          <div>这里来写音频标题这里来写音频标题</div>
        </div>
        <img src="../assets/imgs/share.png" class="article-icon_video" alt="" />
      </div>

      <!-- 文件 -->
      <div v-if="type == 'file'">
        <div class="audio flex flex-center" v-if="toolbar">
          <div class="audioItem flex flex-center">
            <img src="../assets/imgs/file.png" class="audioItem-icon" alt="" />
            <div>{{ item.title }}</div>
          </div>
          <img @click="send(item, 'file')" src="../assets/imgs/share.png" class="article-icon_video" alt="" />
        </div>
        <div class="autoAll" v-else>
          <div class="audio flex flex-center audio-type">
            <div class="audioItem flex flex-center audio-6">
              <img src="../assets/imgs/file.png" class="audioItem-icon" alt="" />
              <div>{{ item.title }}</div>
            </div>
          </div>
          <div class="remind-bottom flex flex-center">
            <div class="flex-item_center remind-bottom_item" style="border-right:1px solid #ededf7;"
              @click="share(item, 1)">
              <img src="../assets/imgs/share.png" alt="" />
              <div>个人</div>
            </div>
            <div class="flex-item_center remind-bottom_item" @click="share(item, 2)">
              <img src="../assets/imgs/share.png" alt="" />
              <div>群聊</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 链接 -->
      <div class="audio flex flex-center" v-if="type == 'link'">
        <div class="audioItem flex flex-center">
          <img src="../assets/imgs/link.png" class="audioItem-icon" alt="" />
          <div>这里来写音频标题这里来写音频标题</div>
        </div>
        <img src="../assets/imgs/share.png" class="article-icon_video" alt="" />
      </div>
    </div>

    <img src="../assets/imgs/addContent.png" @click="router('/AddMaterial')" class="add fixed" v-if="is_admin == 0"
      alt="" />

    <div style="height:400px"></div>
    <tab :current="0" :isShow="6"></tab>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import tab from "@/components/tab.vue";

export default {
  components: { tab },
  data() {
    return {
      nav: [
        // { name: "文章" },
        // { name: "文字" },
        // { name: "视频" },
        // { name: "音频" },
        // { name: "文件" },
        // { name: "链接" },
      ],
      navIndex: 0,
      label: ["企业", "个人"],
      labelIndex: 0,
      type: "",
      items: [],
      toolbar: "", // 聊天工具栏进来就 直接发送文件
      title: "",
      is_admin: 1,
    };
  },
  async mounted() {
    let that = this;

    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    // console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      console.log("进来额");
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid,
        timestamp: result.timestamp,
        nonceStr: result.nonceStr,
        signature: result.signature,
        jsApiList: [
          "sendChatMessage",
          "shareToExternalContact",
          "getContext",
          "shareToExternalChat",
        ],
      });
      that.$wx.ready(function () {
        that.$wx.agentConfig({
          corpid: result.corpid,
          agentid: result.agentid,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature2,
          jsApiList: [
            "sendChatMessage",
            "shareToExternalContact",
            "getContext",
            "shareToExternalChat",
          ],
          success: function (res) {
            console.log("res", res);
            // 回调
            //判断场景
            that.$wx.invoke("getContext", {}, function (res) {
              console.log("getContext res", res);
              if (res.err_msg == "getContext:ok") {
                var entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
                console.log("我的场景", entry);
                if (
                  entry == "chat_attachment" ||
                  entry == "single_chat_tools" ||
                  entry == "group_chat_tools"
                ) {
                  that.toolbar = true;
                }
              } else {
                //错误处理
              }
            });
          },
          fail: function (res) {
            console.log("that.$wx.agentConfig err", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      });

      setTimeout(() => {
        that.getNav();
      }, 700);
    }
  },
  methods: {
    router(path) {
      this.$router.push(path)
    },
    labelClick(index) {
      this.labelIndex = index;
      this.is_admin = index == 0 ? 1 : 0
      this.items = []
      this.getList()
    },
    //分享
    share(item, type, mold) {
      let that = this;
      let attachments = [];
      if (mold == "image") {
        attachments = [
          {
            msgtype: "image",
            image: {
              mediaid: item.media_id,
            },
          },
        ];
      } else if (mold == "file") {
        attachments = {
          msgtype: "file",
          file: {
            mediaid: item.media_id,
          },
        };
      } else if (mold == "video") {
        attachments = {
          msgtype: "video",
          video: {
            mediaid: item.media_id,
          },
        };
      }
      // 参数type == 1个人 ==2发送群
      if (type == 1) {
        that.$wx.invoke(
          "shareToExternalContact",
          {
            text: {
              content: "", // 文本内容
            },
            attachments,
          },
          function (res) {
            if (res.err_msg == "shareToExternalContact:ok") {
            }
          }
        );
      } else {
        that.$wx.invoke(
          "shareToExternalChat",
          {
            text: {
              content: "", // 文本内容
            },
            attachments,
          },
          function (res) {
            if (res.err_msg == "shareToExternalChat:ok") {
            }
          }
        );
      }
    },
    send(item, type) {
      console.log("item", item);
      let obj = {};
      if (type == "image") {
        obj = {
          msgtype: "image", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          image: {
            mediaid: item.media_id, //图片的素材id
          },
        };
      } else if (type == "file") {
        obj = {
          msgtype: "file", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          file: {
            mediaid: item.media_id, //图片的素材id
          },
        };
      } else if (type == "video") {
        obj = {
          msgtype: "video", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          video: {
            mediaid: item.media_id, //图片的素材id
          },
        };
      }
      this.$wx.invoke("sendChatMessage", obj, function (res) {
        if (res.err_msg == "sendChatMessage:ok") {
          //发送成功
        }
      });

      // wx.invoke(
      //   "shareToExternalContact",
      //   {
      //     text: {
      //       content: "", // 文本内容
      //     },
      //     attachments: [
      //       {
      //         msgtype: "image", // 消息类型，必填
      //         image: {
      //           mediaid: item.media_id, //图片的素材id
      //         },
      //       },
      //     ],
      //   },
      //   function(res) {
      //     if (res.err_msg == "shareToExternalContact:ok") {
      //     }
      //   }
      // );
    },
    navClick(item, index) {
      this.type = item.type;
      this.navIndex = index;
      this.getList();
    },
    async getNav() {
      let res = await request._materialType({});
      console.log("nav==", res);
      if (_.toInteger(res.code) == 1) {
        this.nav = res.data;
        this.type = res.data[0].type;
        this.getList();
      }
    },
    async getList() {
      this.items = [];
      let res = await request._getMaterial({
        type: this.type,
        page: 1,
        title: this.title,
        userid: localStorage.getItem("userid"),
        is_admin: this.is_admin,
      });
      console.log("_getMaterial==", res);
      if (_.toInteger(res.code) == 1) {
        this.items = res.data.data;
      }
    },
  },
};
</script>

<style scoped>
.title_name{
  font-size: 34px;
  margin: 20px;
  text-align: center;
}
.topWhile {
  height: 180px;
}

.top {
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9;
}

.nav {
  width: 100%;
  padding: 0 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  background: #fff;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
}

.navItem-name {
  margin-top: 34px;
}

.nav-underline {
  width: 91px;
  height: 6px;
  background: #0558f4;
  border-radius: 3px 3px 3px 3px;
  bottom: 10px;
  position: absolute;
}

.search {
  width: 100%;
  height: 131px;
  border-top: 1px solid #d6e5ee;
  background: #fff;
}

.searchItem {
  width: 690px;
  height: 70px;
  background: #f4f5f7;
  border-radius: 35px 35px 35px 35px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

.searchItem img {
  width: 23px;
  height: 24px;
  margin-right: 10px;
}

.search input {
  width: 104px;
  background: #f4f5f7;
  outline: none;
  border: none;
}

.article {
  margin: 16px auto;
  width: 690px;
  height: 245px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.article-img {
  width: 185px;
  height: 185px;
  border-radius: 25px;
  margin: 0 20px 0 30px;
}

.article-title {
  width: 384px;
  height: 45px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article-text {
  width: 336px;
  height: 66px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 14px 0 25px 0;
  -webkit-line-clamp: 2;
}

.article-label {
  padding: 3px 21px;
  background: #edf3ff;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.article-icon {
  width: 46px;
  height: 46px;
  right: 30px;
  bottom: 30px;
}

.text {
  margin: 16px auto;
  width: 690px;
  height: 224px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.textItem {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin: 30px auto 0;
  width: 576px;
  height: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.video {
  width: 690px;
  height: auto;
  margin: 16px auto;
  background: #fff;
  border-radius: 24px;
}

.video-img {
  width: 579px;
  height: auto;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.article-icon_video {
  margin-left: 28px;
  width: 60px;
  height: 60px;
}

.audio {
  width: 690px;
  height: 169px;
  margin: 16px auto;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.audioItem {
  width: 558px;
  height: 86px;
  background: #f4f5f7;
  border-radius: 16px 16px 16px 16px;
  margin-left: 30px;
}

.audioItem-icon {
  width: 50px;
  height: 50px;
  margin: 0 14px 0 17px;
}

.remind {
  width: 690px;
  margin: 24px auto;
  background: #fff;
  border-radius: 24px;
  padding: 30px 0 0 0;
}

.remind-img {
  width: 100%;
  height: auto;
  border-radius: 24px;
}

.remind-bottom {
  margin-top: 46px;
  height: 81px;
  width: 100%;

  border-top: 1px solid #ededf7;
}

.remind-bottom_item {
  width: 50%;
  height: 81px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
}

.remind-bottom_item img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.autoAll {
  background: #fff;
  width: 690px;
  margin: 16px auto;
  padding-top: 30px;
}

.audio-type {
  margin: 0 auto;
  height: auto;
}

.audio-6 {
  width: 620px;
}

.margin-0 {
  margin-top: 0;
}

.material-nav {
  width: 50%;
  font-weight: bold;
  height: 100px;
  font-size: 30px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #7d8393;
  border-bottom: 1px solid #d6e5ee;
}

.material-select_nav {
  border-bottom: 6px solid #0558f4;
  color: #0558f4;
}


.add {
  width: 80px;
  height: 80px;
  right: 60px;
  bottom: 160px;
}
</style>
